import Validations from './validation';
import Errors from './Errors';
import Terms from '../Terms/index';
import urlParams from "@/helper/urlParams";
import localStore from "@/helper/LocalStore";

import ReferralService from '@/services/referral.service';
import AffiliateService from '@/services/affiliate.service';

const service = new ReferralService();
const affiliateService = new AffiliateService();

export default {
    name: 'referral',
    components: {
        Terms
    },
    data() {
        return {
            step: 'form',
            affiliate: null,
            isLoading: false,
            form: {
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Email: '',
                Phone: '',
                TermsAccepted: false,
            }
        }
    },
    validations: Validations,
    computed: {
        ...Errors,

        company() {
            return {
                Logo: `${this.$ws_server()}/api/files/download/${this.$getCompany().Logo}`,
                Name: this.$getCompany().Name,
                Inventory:this.$getCompany().InventoryUrl
            };
        },

        isCompleted() {
            return localStore.get('completed') === 'true';
        }
    },
    mounted() {
        if (this.isCompleted) {
            this.step = 'completed';

        } else {
            if (urlParams.has('affiliate')) {
                this.form.Affiliate = urlParams.get('affiliate');
                this.get();
            }
            if (urlParams.has('agent')) {
                this.form.Agent = urlParams.get('agent');
            }
        }
    },
    methods: {
        async create() {
            this.isLoading = true;

            const res = await service.create(this.form);

            if (res && !res.error) {
                this.isLoading = false;
                this.step = 'completed';
                localStore.set('completed', true);
            }
        },

        async get() {
            const id = urlParams.get('affiliate');
            const res = await affiliateService.get(id);

            if (res && !res.error) {
                this.affiliate = res.data.affiliate;
            }
        },

        async nextToTerms() {
            await this.$v.$touch();
            if (this.$v.$invalid) return;

            this.step = 'terms';
        },

        termsAccepted(terms) {
            if (terms && !this.form.TermsAccepted) this.form.TermsAccepted = true;
        }
    }
}


