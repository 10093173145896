import { render, staticRenderFns } from "./Teams.html?vue&type=template&id=5ee8c960&scoped=true&"
import script from "./Teams.js?vue&type=script&lang=js&"
export * from "./Teams.js?vue&type=script&lang=js&"
import style0 from "./Teams.scss?vue&type=style&index=0&id=5ee8c960&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ee8c960",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FTeams%2Findex.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports